import React, {useEffect, useState} from "react";
import {List, ListItem, styled, Typography} from "@mui/material";
import {THEME_CONFIG} from "../../../config";

const {poll} = THEME_CONFIG

export default function MultiPollQuestion({risposte, corretta, answers, sendAnswer}) {

    const [answered, setAnswered] = useState(answers);

    useEffect(() => {
        setAnswered(answers);
    }, [answers]);

    const RispostaStyled = styled(ListItem)({
        border: `2px solid ${poll.default}`,
        borderRadius: '0.7rem',
        background: poll.default,
        margin: '1rem 0',
        cursor: 'pointer',

    })

    const computeBkg = (r) => {
        for (const el of answered) {
            if(el === r) {
                return poll.answered
            }
        }
    }

    const computeStyle = (r) => {
        for (const el of answered) {
            if(el === r) {
                return true;
            }
        }
    }

    const Risposte = () => (
        risposte.map((r, i) => (
            <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r),
                border: computeBkg(r) ? `2px solid ${poll.answered}` : `2px solid ${poll.answered}`,
                color: computeStyle(r) ? 'white' : 'black',
            }} onClick={() => rispondi(r)}>
                <Typography variant={"h6"} fontWeight='400'>
                    {r}
                </Typography>
            </RispostaStyled>
        ))
    )

    const rispondi = (r) => {
        if(answered.length < 3 && !answers.includes(r)) {
            const updatedAnswers = [...answered, r];
            console.info(updatedAnswers);
            setAnswered(updatedAnswers);
            sendAnswer(r)
        }

    }

    return (
        <List>
            <Risposte/>
        </List>
    )
}

import React, {useState} from "react";
import {Box, Button, Divider, Typography} from "@mui/material";
import QuestionDialogContent from "../QuestionDialogContent";
import {useParams} from "react-router-dom";
import SuccessCheck from "../../shared/SuccessCheck";
import {motion, useMotionValue} from "framer-motion";

export const AltroQuestion = ({elementPlaying, theme, url, user, onClose}) => {
    return (
        <SessioneContent elementPlaying={elementPlaying} theme={theme} url={url} user={user} onClose={onClose}/>
    )
}

const SessioneContent = ({elementPlaying, theme, url, user, onClose}) => {
    console.log("elementPlaying:", elementPlaying)
    const selectedSession = sessioni[elementPlaying.id] // DEVE ESSERE L'ID DELLA DOMANDA DELLA SESSIONE X (tipo altro)

    const {id} = useParams()
    console.log("props:", user.uid)

    const [success, setSuccess] = useState(false)
    const [openContext, setOpenContext] = useState(false)

    let progress = useMotionValue(90)

    return (
        //POPUP INUTILIZZATO (openContext)
        <Box pb={4}>

            {!success ? <>
                    <Box style={{ display:'flex', flexDirection:'column', justifyContent:'center', marginTop:'3vh', marginBottom:'3vh'}}>
                        { selectedSession?.description === 'd1' &&
                            <Typography gutterBottom variant={'p'} textAlign={'justify'} style={{padding:'1rem', paddingTop:'0', fontWeight:'normal', fontSize:'18px'}}>
                                Value for Money (VFM) can be defined as the optimal combination of cost and quality of a good or service,
                                required to meet customer requirements. According to the European authority, around 1/3 of insurance
                                customers are not satisfied with the value for money offered by their products.
                                <br/>
                                During the last years, the European regulator attention to VFM has been moving from a principle-based
                                approach, with qualitative indications, to a very detailed and analytical approach, with strong
                                recommendations. The current focus is on IBIPs but, in the future, other kinds of business could be put
                                under scrutiny (e.g., commission on protection business, loss ratio, P&C etc.).
                            </Typography>
                        }
                        { selectedSession?.description === 'd2' &&
                            <Typography gutterBottom variant={'p'} textAlign={'justify'} style={{padding:'1rem', paddingTop:'0', fontWeight:'normal', fontSize:'18px'}}>
                                Generali’s P&C business has been growing steadily, breaking the 30 €Bln GWP threshold at YE 2023. However, with profitability under pressure due to persisting inflation and an adverse Nat-Cat evolution, strengthening our Technical Excellence framework will be key to drive profitable growth throughout the next strategic cycle.
                            </Typography>
                        }
                        <Divider color={'black'}/>
                    </Box>


                    {<Box mt={5}>
                        {
                            selectedSession?.questions?.map((domanda, index) => (
                                <Box key={index} my={2}>
                                    <Typography gutterBottom textAlign={'center'} style={{padding:'1rem', paddingTop:'0', fontSize:'20px', fontWeight:'bold'}}>
                                        Question {index+1}
                                    </Typography>
                                    {domanda.id === 'm2yiJBqxYaMg5uUE2mS1' &&
                                        <Typography gutterBottom textAlign={'justify'} style={{padding:'1rem', paddingTop:'0', fontSize:'18px', fontWeight:'normal'}}>
                                            Generali hybrid models are very appreciated by employees, as shown in the high results (over 80% of
                                            favorable) of 2023 Pulse Survey questions across performance, innovation, connectivity, energy in the Next
                                            Normal session. However, some room for improvement has been identified in areas such as cultural connection
                                            and sense of belonging, occasions to team-up, structured and concrete wellbeing initiatives, on-boarding of
                                            new joiners and others, more collaborative and comfortable working spaces.
                                        </Typography>
                                    }
                                    {domanda.id === '4T2A0Q2K3JNW5Xg0Feaf' &&
                                        <Typography gutterBottom textAlign={'justify'} style={{
                                            padding: '1rem',
                                            paddingTop: '0',
                                            fontSize: '18px',
                                            fontWeight: 'normal'
                                        }}>
                                            Demographic and technological change both impact our workforce and Strategic Workforce Planning
                                            deeply.
                                            <br/><br/>
                                            <span style={{fontWeight:'bold'}}> Demographics: </span>Generali has an average age of 43.9 (vs 40.9 Allianz and 41.3 AXA) and a number of
                                            technical experts are set to retire in the next 3 to 5 years in Western Europe. It is necessary to have a plan to
                                            manage competence loss through upskilling/ reskilling and the hiring of younger specialists. On this last
                                            point, 75% of companies report difficulties in talent attraction.
                                        </Typography>
                                    }
                                    {domanda.id === 'mBPn26jT80HQRSDFjlb5' &&
                                        <Typography gutterBottom textAlign={'justify'} style={{
                                            padding: '1rem',
                                            paddingTop: '0',
                                            fontSize: '18px',
                                            fontWeight: 'normal'
                                        }}>
                                            <span style={{fontWeight: 'bold'}}>AI/ Gen AI: </span>The insurance industry
                                            is among the most impacted by AI and Gen AI, with a significant impact
                                            on 68% of jobs, just behind banking at 72%. While Generative AI is impacting
                                            the majority of the
                                            professional families, “traditional” AI disruption is fundamentally
                                            transforming in particular key areas such
                                            as underwriting, claims processing and operations.
                                            <br/><br/>
                                            In Generali, over 50% of our admin workforce will be exposed to AI/automation (in particular, Claims,
                                            Sales Support, Customer Service, Policy Issuance and Underwriting Job Families). It is key for Generali to
                                            effectively manage implications in terms of reskilling/redeployment, upskilling, new role acquisition.
                                            When it comes to GenAI, all Job Families will be affected in terms of personal productivity and Generali has
                                            already defined to make GenAI solution available to the majority of the workforce.
                                        </Typography>
                                    }
                                    <QuestionDialogContent idPlaying={domanda.id} url={url}
                                                           user={user}
                                                           theme={theme}/>
                                    <Divider color={'black'}/>
                                </Box>
                            ))
                        }
                        <Box style={{margin:'auto', marginTop:'10%',flexDirection:'column',  justifyContent:'center' }}>
                            <Typography gutterBottom style={{textAlign:'center', fontSize:'18px', fontWeight:'bold'}}>
                                QUESTIONS FOR TABLE DISCUSSION <br/> (WORKING SESSIONS – June 12)
                            </Typography>
                            {
                                selectedSession?.extras?.map((extra, index) => (
                                    <>
                                        <Box key={index} my={2} style={{textAlign:'justify', fontSize:'18px', fontWeight:'bold'}}>
                                            {extra.text === 'openQuestion-1-1' &&
                                                <Typography>
                                                    Given the potential value at risk that the Group could face, it is necessary to manage the risk coming from
                                                    Value for Money and transform it into an opportunity to further strengthen Generali’s leadership in this
                                                    area. <span style={{fontWeight:'bold'}}>Which levers would you activate to address the Value for Money topic</span>  (e.g., review the product offer
                                                    / structure; increase the Protection component; improve the underlying performance; etc.)<span style={{fontWeight:'bold'}}>?</span>
                                                </Typography>
                                            }
                                            {extra.text === 'openQuestion-1-2' &&
                                                <Typography>
                                                    There is the need to challenge the current value sharing between
                                                    Customers, Company, Distributors and Asset
                                                    Managers by exploring all the possible areas of intervention (e.g.,
                                                    distribution costs, administrative costs,
                                                    investment management costs …).
                                                    <span style={{fontWeight: 'bold'}}> On which areas of intervention do you see major room for improvement? Which constraints and challenges do you see?</span>
                                                </Typography>
                                            }
                                            {extra.text === 'openQuestion-1-3' &&
                                                <Typography>
                                                    Our Group is working to properly address the VFM topic by
                                                    coordinating the advocacy activities with the
                                                    European regulator, providing technical support / guidance and
                                                    improving the collaboration with Generali
                                                    Investment Holding for the underlying funds' selection.<span
                                                    style={{fontWeight: 'bold'}}> In which areas do you believe the Group should
                                                    accelerate or mostly support your Legal Entity on the VFM topic? </span>
                                                </Typography>
                                            }
                                            {extra.text === 'openQuestion-2-1' &&
                                                <Typography>
                                                    Over the past years our Motor business has been particularly exposed
                                                    to claims inflation with deteriorating
                                                    profitability resulting in a Group CY CoR Undiscounted recorded at
                                                    YE 2023 in the heights of 103,9%.<span style={{fontWeight: 'bold'}}> Based
                                                    on your experience and current trends observed in your local market, how can we ensure to structurally
                                                    restore profitability in Motor leveraging - among the others - the implementation of a tailored portfolio
                                                    management and pricing strategy, as well as the relevant claims management actions (e.g., steering,
                                                    anti-fraud, LAE reduction)? </span>
                                                </Typography>
                                            }
                                            {extra.text === 'openQuestion-2-2' &&
                                                <Typography>
                                                    Improving the productivity of claims management is a strategic priority. Our goal is to transform Claims Notification by improving the information flow with customers / distributors focusing on:
                                                    <br/>
                                                    - Simplification of notification activities for distributors
                                                    <br/>
                                                    - Enhancement of customer self-service capabilities
                                                    <br/>
                                                    - Use of data & AI for assessment of claims information and assignment to best journey
                                                    <br/>
                                                    <span style={{fontWeight: 'bold'}}>How can we best implement this model at local level? Based on your knowledge of the local market, which claims types should be addressed first / display a higher improvement potential? </span>
                                                </Typography>
                                            }
                                            {extra.text === 'openQuestion-2-3' &&
                                                <Typography>
                                                    Generali continuously seeks to integrate sustainability into the
                                                    value chain. An area of focus is claims
                                                    management, where sustainable practices can greatly impact both the
                                                    environment and business
                                                    operations.
                                                    <br/>
                                                    <span style={{fontWeight: 'bold'}}>
                                                        Among the identified actions mentioned hereafter, which ones have already been undertaken to embed
                                                        sustainability in claims management? Are there any other that come to your mind and that shall be
                                                        included within Generali's next strategy? E.g.
                                                        <br/>
                                                        - Foster remote interactions and communication
                                                        <br/>
                                                        - Repair/Reconstruct instead of Replace
                                                        <br/>
                                                        - Reduction of carbon emissions during repair
                                                        <br/>
                                                        - Promotion of circular economy (e.g., use of recycled materials, etc.)
                                                    </span>
                                                </Typography>
                                            }
                                            {extra.text === 'openQuestion-3-1' &&
                                                <Typography>
                                                    <span style={{fontWeight: 'bold'}}>
                                                        How can we scale-up faster? What are the key success factors for the delivery?
                                                    </span>
                                                    (e.g., mandatory
                                                    technologies / use cases; pooling data scientists for medium / small geographies to make sure there is
                                                    critical mass and real cross fertilization)
                                                </Typography>
                                            }
                                            {extra.text === 'openQuestion-3-2' &&
                                                <Typography>
                                                    <span style={{fontWeight: 'bold'}}>
                                                        What are the main challenges, also from a regulatory and ethical standpoint, and risks associated with data, AI, and automation for insurers, and how does Generali address and mitigate them?
                                                    </span>
                                                </Typography>
                                            }
                                            {extra.text === 'openQuestion-4-1' &&
                                                <Typography>
                                                    What Golden Rules and changes to the current model would you adopt to make hybrid work more effective and
                                                    conducive to cultural connection, sense of belonging, teamwork, while ensuring productivity and retention?
                                                </Typography>
                                            }
                                            {extra.text === 'openQuestion-4-2' &&
                                                <Typography>
                                                    What actions can be put in place to effectively manage workforce planning (reskilling/redeployment,
                                                    upskilling, talent acquisition, other mitigation actions) in a context of demographic and technological
                                                    change?
                                                </Typography>
                                            }
                                        </Box>
                                        <Divider color={'black'}></Divider>
                                    </>
                                ))
                            }

                        </Box>
                        <Box style={{margin:'auto !important', marginTop:'20px', display:'flex', justifyContent:'center'}}>
                            <Button variant={'default'} style={{margin:'auto !important'}} onClick={onClose}>
                                BACK TO HOMEPAGE
                            </Button>
                        </Box>
                    </Box>
                    }
                </>
                :
                <>
                    <Typography gutterBottom variant={'h5'}>Grazie per avere risposto!</Typography>
                    <Box pt={2} style={{width: '40%', margin: 'auto'}}>
                        <motion.div
                            initial={{x: 0}}
                            animate={{x: 100}}
                            style={{x: progress}}
                            transition={{duration: 0.8}}
                        />
                        <SuccessCheck progress={progress}/>
                    </Box>
                </>
            }
        </Box>
    )
}

const sessioni = {
    "slrRui7CNklnjhajVzFQ": {
        label: "Sessione #1",
        description: "d1",
        questions: [
            {
                id: "KpfwlOFQ4yJFQp7orkAa"
            },
            {
                id: "7GhVCFsE33MJcdqbdwWa"
            }
        ],
        extras: [
            {
                text: "openQuestion-1-1"
            },
            {
                text: "openQuestion-1-2"
            },
            {
                text: "openQuestion-1-3"
            },
        ]
    },
    "bMuJSpPWYs46JxYtGlN6" : {
        label: "Sessione #2",
        description: "d2",
        questions: [
            {
                id: "m87RbpXvrWDnhNSrvhG9"
            },
            {
                id: "vj0VvLpzMwM0EzBD4sLQ"
            }
        ],
        extras: [
            {
                text: "openQuestion-2-1"
            },
            {
                text: "openQuestion-2-2"
            },
            {
                text: "openQuestion-2-3"
            }
        ]
    },
    "RkVN8W0hQrEfjwxAoIcu" : {
        label: "Sessione #3",
        description: "d3",
        questions: [
            {
                id: "6gDqFekABxt9EMokLQYA"
            },
            {
                id: "LOmSADTdB2qtVF9CjwSx"
            },
            {
                id: "cOJQt9qK0v3tr2GLYOvn"
            }
        ],
        extras: [
            {
                text: "openQuestion-3-1"
            },
            {
                text: "openQuestion-3-2"
            }
        ]
    },
    "0h6etsL5r7YfnGgazDpo" : {
        label: "Sessione #4",
        description: "Supporting the New Strategic journey",
        questions: [
            {
                id: "m2yiJBqxYaMg5uUE2mS1"
            },
            {
                id: "4T2A0Q2K3JNW5Xg0Feaf"
            },
            {
                id: "mBPn26jT80HQRSDFjlb5"
            }
        ],
        extras: [
            {
                text: "openQuestion-4-1"
            },
            {
                text: "openQuestion-4-2"
            }
        ]
    }
}
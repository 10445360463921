import React, {useEffect, useState} from "react";
import {firestore} from "../../firebase/clientApp";
import {Box, Container, Stack, Typography, useTheme} from "@mui/material";
import {Textfit} from "react-textfit";
import {QuestionSwitch} from "./QuestionSwitch";

function QuestionBar({domanda}) {
    const theme = useTheme()
    return (
        <Container sx={{height: '25%'}}>
            <Box sx={{position: 'relative', width: '100%', height:'100%'}}>
                <Typography
                    style={{
                        color: theme.palette.text,
                        fontWeight: 'bold',
                        fontSize:'20px',
                        width: '100%',
                        height: '100%',
                        textAlign: 'justify',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                }}
                >
                    {domanda}
                </Typography>
            </Box>
        </Container>
    )
}

function QuestionContent({elementPlaying, theme, url, user, onClose}) {
    return(
        <Container sx={{height:'75%', position:'relative', paddingTop:'20px', textAlign:'center'}}>
            <QuestionSwitch elementPlaying={elementPlaying} theme={theme} url={url} onClose={onClose}/>
        </Container>
    )
}


export default function QuestionDialogContent({url, user,idPlaying,open, theme, onClose}) {
    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(()=>{
        idPlaying && getElementPlaying()
    }, [idPlaying])

    function getElementPlaying() {
        firestore.collection(url+'/queue').doc(idPlaying).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }
    return(
        <Stack justifyContent={"space-between"} style={{height:'100%', zIndex:1}}>
            <QuestionBar tipo={elementPlaying.tipo} colore={elementPlaying.colore} domanda={elementPlaying.domanda}/>
            <QuestionContent elementPlaying={elementPlaying} user={user} theme={theme} url={url} onClose={onClose}/>
        </Stack>
    )
}
